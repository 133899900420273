<template><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64" :width="width" :height="height"><g :fill="fill"><path d="M61.8 16.8L34.2 1.6c-1.4-.8-3-.8-4.4 0L2.2 16.8c-.7.4-1 1.2-.9 2s.9 1.3 1.7 1.3h3.2V49.3c-1.6.8-2.7 2.4-2.7 4.3v4.7c0 2.6 2.1 4.8 4.8 4.8h47.5c2.6 0 4.8-2.1 4.8-4.8v-4.7c0-1.9-1.1-3.5-2.7-4.3V20.2v-.1H61c.8 0 1.5-.5 1.7-1.3.2-.8-.2-1.6-.9-2zM31.5 4.7c.3-.2.7-.2 1 0l21.7 11.9H9.8L31.5 4.7zm12.4 44.1H19.5V20.2v-.1h24.4V48.8zM16 20.1v28.7H9.7V20.2v-.1H16zm41 38.1c0 .7-.6 1.3-1.3 1.3H8.3c-.7 0-1.3-.6-1.3-1.3v-4.7c0-.7.6-1.3 1.3-1.3h47.5c.7 0 1.3.6 1.3 1.3v4.7zm-2.7-38v28.6h-6.9V20.2v-.1l6.9.1c0-.1 0-.1 0 0z"></path><path d="M29.9 29.6H36c1 0 1.8-.8 1.8-1.8s-.8-1.7-1.8-1.7h-2V25c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v1.1h-.6c-2.8 0-5.1 2.3-5.1 5s2.3 5 5.1 5h2.8c.9 0 1.6.7 1.6 1.5s-.7 1.5-1.6 1.5h-6.1c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h3.9v1.1c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8v-1.2c2.2-.6 3.8-2.5 3.8-4.8 0-2.8-2.3-5-5.1-5h-2.8c-.9 0-1.6-.7-1.6-1.5s.7-1.7 1.6-1.7z"></path></g></svg></template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: "100%"
		},
		height: {
			type: [Number, String],
			default: "100%"
		},
		strokewidth: {
			type: [Number, String],
			default: 1
		},
		fill: {
			type: String,
			default: "currentColor"
		},
		secondaryfill: {
			type: String
		}
	},
	computed: {
		_secondaryfill: function () {
			return this.secondaryfill || this.fill
		}
	}
}
</script>